import {SaveOutlined} from "@ant-design/icons";
import {Button, Col, DatePicker, Input, Modal, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {AppContext} from "../AppContext";
import {isValidEmail} from "../Functions/Functions";

const {Option} = Select;

const ModalRequisitions = ({
                               id,
                               title,
                               handleCloseModal,
                               products,
                               desks,
                               open,
                           }) => {
    const cfg = window.REACT_APP_CONFIG;

    const [reqUID, setReqUID] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [room, setRoom] = useState("");
    const [product, setProduct] = useState("");
    const [employee, setEmployee] = useState("");
    const [desk, setDesk] = useState("");
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [observations, setObservations] = useState("");
    const [returnId, setReturnId] = useState();

    const {guestsInfo, setGuestsInfo} = useContext(AppContext);

    useEffect(() => {
        const getReq = async () => {
            if (id != null) {
                const res = await fetch(`${cfg.api_url}/reqs?Id=${id}`, {
                    method: "GET",
                });
                let response = await res.json();
                response = response.info[0];
                setReqUID(response.uid);
                setClientName(response.client_name);
                setClientEmail(response.client_email);
                setEmployee(response.employeeId);
                setTotal(response.Total || response.total);
                setRoom(response.room);
                setQuantity(response.Quantity || response.quantity);
                setObservations(response.observations);
                setProduct(response.productId);
                setDesk(response.desk);
                setReturnId(response.returnId);
                setCheckIn(moment(response.checkIn));
                setCheckOut(moment(response.checkOut));
            }
        };
        getReq();
    }, [id]);

    const handleRequisit = async () => {
        if (
            clientName.length <= 0 ||
            product.length <= 0 ||
            employee.length <= 0 ||
            total.length <= 0 ||
            quantity.length <= 0
        ) {
            toast.error("You must fill all the fields.");
            return;
        }

        const body = {
            productId: parseInt(product),
            client_name: clientName,
            client_email: clientEmail,
            room: room,
            employeeId: employee,
            total: parseFloat(total),
            quantity: parseFloat(quantity),
            observations: observations,
            desk: parseInt(desk),
            resCheckIn:
                checkIn !== null && checkIn !== undefined ? checkIn.format() : null,
            resCheckOut:
                checkOut !== null && checkOut !== undefined ? checkOut.format() : null,
        };

        const res = await fetch(`${cfg.api_url}/reqs/new`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        const response = await res.json();
        if (response.code === 200) {
            toast.success("Requisition inserted sucessfully!");
            clearFields();
            handleCloseModal();
            return;
        } else {
            toast.error(
                "An error has occurred, if you are a developer check the console for more info"
            );
            return;
        }
    };

    const handleUpdateRequisit = async () => {
        if (product.length <= 0) {
            toast.error("The product cannot be empty.");
            return;
        }
        if (clientEmail.length > 0 && !isValidEmail(clientEmail)) {
            toast.error("This email isn't valid");
            return;
        }

        const body = {
            Id: parseInt(id),
            productId: parseInt(product),
            client_name: clientName,
            client_email: clientEmail,
            room: room,
            employeeId: employee,
            total: parseFloat(total),
            quantity: parseFloat(quantity),
            observations: observations,
            desk: parseInt(desk),
            resCheckIn:
                checkIn !== null && checkIn !== undefined ? checkIn.format() : null,
            resCheckOut:
                checkOut !== null && checkOut !== undefined ? checkOut.format() : null,
        };

        const res = await fetch(`${cfg.api_url}/reqs/update`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        const response = await res.json();
        if (response.code === 200) {
            toast.success("Requisition updated sucessfully!");
            clearFields();
            handleCloseModal();
            return;
        } else {
            toast.error(
                "An error has occurred, if you are a developer check the console for more info"
            );
            return;
        }
    };

    const clearFields = () => {
        setReqUID("");
        setClientName("");
        setClientEmail("");
        setRoom("");
        setProduct("");
        setEmployee("");
        setDesk("");
        setCheckIn(null);
        setCheckOut(null);
        setTotal(0);
        setQuantity(0);
        setObservations("");
        setReturnId();
    };

    const changeSelectedInfo = (room) => {
        let roomNo = room.value.split("_")[0];
        let resNo = room.value.split("_")[1];
        let info = guestsInfo.find((gi) => gi.room === roomNo && gi.reservationNumber === resNo);
        setClientEmail(info.email);
        setClientName(info.guestName);
        setRoom(room.value);
        setCheckIn(moment(info.checkIn));
        setCheckOut(moment(info.checkOut));
    };

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const RoomsSelect = () => {
        return (
            <Select
                className="w-full"
                showSearch
                labelInValue
                value={room}
                optionLabelProp="label"
                onChange={changeSelectedInfo}
                filterOption={filterOption}
            >
                {guestsInfo !== null &&
                    guestsInfo !== undefined &&
                    guestsInfo.length > 0 &&
                    Object.values(guestsInfo).map((guestInfo) => (
                        <Option
                            value={`${guestInfo.room}_${guestInfo.reservationNumber}`}
                            label={guestInfo.room}
                            key={guestInfo.room}
                        >
                            <div className={"flex justify-between"}>
                                <span className="font-bold">{guestInfo.room}</span>
                                <span>{guestInfo.guestName}</span>
                                <span className="text-ellipsis">{guestInfo.email}</span>
                            </div>
                        </Option>
                    ))}
            </Select>
        );
    };

    return (
        <Modal
            open={open}
            title={title}
            onCancel={() => {
                handleCloseModal();
                clearFields();
            }}
            footer={null} // No footer
        >
            <div className="mt-2 mb-4 flex">
                {reqUID !== null && reqUID.trim().length !== 0 ? (
                    <>
                        UID <div className="font-bold ml-2">{reqUID.split("-")[0]}</div>-
                        <div>{reqUID.split("-").slice(1).join("-")}</div>
                    </>
                ) : (
                    <></>
                )}
            </div>

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <p>Client</p>
                    <Input
                        placeholder="Client"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                </Col>
                <Col span={12}>
                    <p>Client E-mail</p>
                    <Input
                        placeholder="Client E-mail"
                        value={clientEmail}
                        onChange={(e) => setClientEmail(e.target.value)}
                    />
                </Col>
                <Col span={12}>
                    <p>Room</p>
                    <RoomsSelect/>
                </Col>
                <Col span={12}>
                    <p>Product</p>
                    {products !== null && products !== undefined && (
                        <Select
                            showSearch
                            placeholder="Select a product"
                            options={products.map((product) => ({
                                value: product.id,
                                label: product.name,
                            }))}
                            value={product}
                            className="w-full"
                            onChange={(value) => setProduct(value)}
                        />
                    )}
                </Col>
                <Col span={12}>
                    <p>User</p>
                    <Input
                        placeholder="User"
                        value={employee}
                        onChange={(e) => setEmployee(e.target.value)}
                    />
                </Col>
                <Col span={12}>
                    <p>Desk</p>
                    {desks !== null && desks !== undefined && (
                        <Select
                            showSearch
                            placeholder="Select a desk"
                            options={desks.map((desk) => ({
                                value: desk.id,
                                label: desk.description,
                            }))}
                            value={desk}
                            className="w-full"
                            onChange={(value) => setDesk(value)}
                        />
                    )}
                </Col>
                <Col span={12}>
                    <p>Total</p>
                    <Input
                        placeholder="Total"
                        value={total}
                        onChange={(e) => setTotal(e.target.value)}
                        disabled={true}
                        type="number"
                        min={0}
                    />
                </Col>
                <Col span={12}>
                    <p>Quantity</p>
                    <Input
                        placeholder="Quantity"
                        value={quantity}
                        onChange={(e) => {
                            setQuantity(e.target.value);
                            setTotal(e.target.value * 15);
                        }}
                        type="number"
                        min={0}
                    />
                </Col>
                <Col span={12}>
                    <p>Check-In</p>
                    <DatePicker
                        placeholder="checkIn"
                        className="w-full"
                        value={checkIn}
                        onChange={(date) => {
                            setCheckIn(date);
                        }}
                    />
                </Col>
                <Col span={12}>
                    <p>Check-Out</p>
                    <DatePicker
                        placeholder="checkOut"
                        className="w-full"
                        value={checkOut}
                        onChange={(date) => {
                            setCheckOut(date);
                        }}
                    />
                </Col>
                <Col span={24}>
                    <p>Observations</p>
                    <TextArea
                        placeholder="Observations"
                        value={observations}
                        onChange={(e) => setObservations(e.target.value)}
                        autoSize={{minRows: 2, maxRows: 4}}
                    />
                </Col>
                <Col span={24} className="[&>*]:flex [&>*]:ml-auto">
                    {id == null ? (
                        <Button
                            type="primary"
                            onClick={handleRequisit}
                            className="!bg-blue-500 "
                        >
                            <SaveOutlined className="!flex !my-auto"/>
                        </Button>
                    ) : (
                        <div className="!flex !my-auto gap-3 justify-end">
                            <Button
                                className="!bg-blue-500"
                                type="primary"
                                onClick={handleUpdateRequisit}
                            >
                                <SaveOutlined className="!flex !my-auto"/>
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default ModalRequisitions;
