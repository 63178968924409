import { Button, Modal } from "antd";
import ButtonNew from "./ButtonNew";
import ButtonRevert from "./ButtonRevert";

const ConfirmationModal = ({ title, text, handleYes, handleNo, visible }) => {
  return (
    <Modal
      title={title}
      open={visible}
      footer={(_) => (
        <>
          <Button className="!bg-blue-500" type="primary" onClick={handleYes}>
            Yes
          </Button>
          <Button type="primary" className="text-blue-500" onClick={handleNo}>
            No
          </Button>
        </>
      )}
      okText="Yes"
      cancelText="No"
    >
      <p>{text}</p>{" "}
    </Modal>
  );
};

export default ConfirmationModal;
