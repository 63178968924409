import Close from "../icons/components/Close";

const FiltersTitle = ({ open, setPopoverOpen }) => {
  return (
    <div className="flex my-auto justify-between">
      <div className="font-bold">Filters</div>
      <div
        className="w-5 h-5 hover:bg-[#d4e2fd79] hover:cursor-pointer rounded-md flex justify-center items-center"
        onClick={(e) => setPopoverOpen(false)}
      >
        <Close />
      </div>
    </div>
  );
};

export default FiltersTitle;
