import React from "react";
import TableRequisitions from "../Components/TableRequisitions";

const Requisitions = () => {
  return (
    <div>
      <TableRequisitions />
    </div>
  );
};

export default Requisitions;
