import moment from "moment";

const ReceiptTemplate = ({ req, style }) => {
    const { hotel } = window.REACT_APP_CONFIG;

    return (
      <div className="pt-2 p-5" key={req?.id} style={style}>
        <h2>{hotel.name}</h2>
        <p>{hotel.address.street}</p>
        <p>{hotel.address.city}</p>
        <p>{hotel.address.postalCode} {hotel.address.state}</p>
        <p>{hotel.address.country} {hotel.phone}</p>
        <p className="mt-8">Resource Deposits</p>
        <p>-------------------------------------------------</p>
        <p>
          REF. ID: <h1>{req?.uid}</h1>
        </p>
        <p>{moment(req?.createdAt).format("DD/MM/YYYY HH:mm")}</p>
        <p>Room {req?.room}</p>
        <h3>{req?.client_name}</h3>
        <p>--------------------------------------</p>
        {req?.quantity} {req?.productName} {req?.total} €
        <p>--------------------------------------</p>
        <p>-------------------------------------------------</p>
        {moment().format("DD/MM/YYYY HH:mm")}
      </div>
    );
}

export default ReceiptTemplate;