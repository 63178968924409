import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [guestsInfo, setGuestsInfo] = useState([]);
  const [filterStatus, setFilterStatus] = useState(0);

  return (
    <AppContext.Provider
      value={{ guestsInfo, setGuestsInfo, filterStatus, setFilterStatus }}
    >
      {children}
    </AppContext.Provider>
  );
};
