import { Button, Col, Input, Row, Select } from "antd";
import { useContext, useState } from "react";
import { AppContext } from "../AppContext";

const FiltersContent = ({ open, handleApply }) => {
  const placeholders = {
    text: "Type something",
  };
  const { filterStatus, setFilterStatus } = useContext(AppContext);

  const [clientName, setClientName] = useState(null);
  const [room, setRoom] = useState(null);

  return (
    <>
      <Row className="flex justify-between [&>*]:flex [&>*]:flex-col [&>*]:gap-4 mt-5">
        <Col
          sm={10}
          md={10}
          xl={10}
          xs={10}
          xxl={10}
          lg={10}
          className="[&>*]:my-auto"
        >
          <div className="font-bold text-gray-400 text-md">
            Filter by Client
          </div>
          <div className="font-bold text-gray-400 text-md">Filter by Room</div>
          <div className="font-bold text-gray-400 text-md">Status</div>
        </Col>
        <Col sm={14} md={14} xl={14} xs={14} xxl={14} lg={14}>
          <Input
            placeholder={placeholders.text}
            onChange={(e) => setClientName(e.target.value)}
            value={clientName}
          />
          <Input
            placeholder={placeholders.text}
            onChange={(e) => setRoom(e.target.value)}
            value={room}
          />
          <Select
            className="w-full"
            defaultValue={0}
            value={filterStatus}
            options={[
              { value: -1, label: "All Statuses" },
              { value: 0, label: "On Going" },
              { value: 1, label: "Returned" },
            ]}
            onChange={val => {setFilterStatus(val)}}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="w-full flex justify-between">
          {/*The 3rd parameter in handleApply is to see if it closes the popover or not */}
          <Button
            onClick={(e) => {
              setClientName("");
              setRoom("");
              handleApply("", "", false);
            }}
          >
            Clear filters
          </Button>
          <Button
            type="primary"
            className="!bg-blue-500"
            onClick={(e) => handleApply(clientName, room, true, filterStatus)}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default FiltersContent;
