export const isValidEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

export function EntruncateString(maxChars, string) {
  if(string === undefined || string === null){
    return;
  }
  if (maxChars <= 3) {
    return "...";
  }
  if (string.length <= maxChars) {
    return string;
  }
  return string.slice(0, maxChars - 3) + "...";
}
