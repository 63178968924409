import React, { useContext, useEffect, useRef, useState } from "react";
import ModalRequisitions from "./ModalRequisitions";
import { toast } from "react-toastify";
import ConfirmationModal from "./ConfirmationModal";
import {
  Input,
  Table,
  DatePicker,
  Row,
  Col,
  Button,
  Popover,
  Dropdown,
} from "antd";
import moment from "moment/moment";
import Filter from "../icons/components/Filter";
import Edit from "../icons/components/Edit";
import { IoMdAddCircleOutline } from "react-icons/io";
import FiltersContent from "./FiltersContent";
import FiltersTitle from "./FiltersTitle";
import { EntruncateString } from "../Functions/Functions";
import {
  EllipsisOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import printJS from "print-js";
import ReceiptTemplate from "./ReceiptTemplate";
import ReactDOM from "react-dom";
import { AppContext } from "../AppContext";

const TableRequisitions = () => {
  const cfg = window.REACT_APP_CONFIG;
  const { RangePicker } = DatePicker;

  const [paginationConfig, setPaginationConfig] = useState({
    current: 1,
    pageSize: 25,
  });
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [products, setProducts] = useState([]);
  const [desks, setDesks] = useState([]);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [filters, setFilters] = useState({
    FromDate: null,
    ToDate: null,
    Client: null,
    Room: null,
    FreeSearch: null,
    Status: null,
  });


  const { guestsInfo, setGuestsInfo, filterStatus, setFilterStatus } =
    useContext(AppContext);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    const handleKeyUp = (e) => {
      const keyPressed = e.key;
      if (keyPressed === "Enter" && !isModalRequisitionsOpened) {
        handleSearch();
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [filters]);

  const [isModalRequisitionsOpened, setIsModalRequisitionsOpened] =
    useState(false);
  const [isConfirmationModalOpened, setIsConfirmationModalOpenend] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [updateId, setUpdateId] = useState();
  const [revertId, setRevertId] = useState();
  const [currentEmp, setCurrentEmp] = useState("ABC");

  const handleUpdate = (id) => {
    setUpdateId(id);
    setIsModalRequisitionsOpened(true);
  };

  const handleApply = (clientName, room, closePopOver, status) => {
    setFilters({ ...filters, Client: clientName, Room: room, Status: status });
    setPopoverOpen(!closePopOver);
  };

  const handleNewRequisition = () => {
    setIsModalRequisitionsOpened(true);
  };

  const handleOpenConfirmationModal = (id) => {
    setIsConfirmationModalOpenend(true);
    setRevertId(id);
  };

  const handleRevertRequisition = () => {
    const revertRequisition = async () => {
      const resp = await fetch(`${cfg.api_url}/reqs?Id=${revertId}`, {
        method: "GET",
      });
      let responseData = await resp.json();
      responseData = responseData.info[0];
      const body = {
        requisitionId: revertId,
        returningEmployeeId: responseData.employeeId,
        observations: responseData.observations,
        quantItemsReturned: responseData.quantity,
        totalReturned: responseData.total,
        desk: responseData.desk,
        room: responseData.room,
      };
      const res = await fetch(`${cfg.api_url}/reqs/return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const response = await res.json();
      if (response.code == 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
      handleCloseModal();
      handleSearch();
    };
    revertRequisition();
  };

  const handleCloseModal = () => {
    setIsModalRequisitionsOpened(false);
    setIsConfirmationModalOpenend(false);
    handleSearch()
    setUpdateId(null);
  };

  const handleSearch = () => {
    const { FromDate, ToDate, Client, Room } = filters;

    const searchFun = async () => {
      let params = [];
      if (FromDate != null) params.push(`fromDate=${FromDate}`);
      if (ToDate != null) params.push(`toDate=${ToDate}`);
      if (Client != null) params.push(`Client=${Client}`);
      if (Room != null) params.push(`Room=${Room}`);
      params.push(`returned=${filterStatus}`);

      let paramsString = params.length > 0 ? `?${params.join("&")}` : "";
      const res = await fetch(`${cfg.api_url}/reqs${paramsString}`, {
        method: "GET",
      });
      const response = await res.json();
      setData(response);
    };
    searchFun();
  };

  const printRef = useRef(null);

  const handlePrint = (req) => {
    ReactDOM.unmountComponentAtNode(printRef.current);
    ReactDOM.render(<ReceiptTemplate req={req} />, printRef.current);
    printJS({
      printable: `printArea${req.id}`,
      type: "html",
      showModal: true,
    });
  };

  useEffect(() => {
    const getProducts = async () => {
      const res = await fetch(`${cfg.api_url}/product`, {
        method: "GET",
      });

      const response = await res.json();
      setProducts(response);
    };
    getProducts();
    const getDesks = async () => {
      const res = await fetch(`${cfg.api_url}/desks`, {
        method: "GET",
      });
      const response = await res.json();
      setDesks(response.info);
    };
    getDesks();
    const searchClientInfo = async () => {
      const res = await fetch(`${cfg.api_url}/client/getClientInfo`, {
        method: "GET",
      });
      let response = await res.json();
      if (response.status.code === 200) {
        setGuestsInfo(response.info);
      } else {
        toast.error(response.status.message);
      }
    };
    searchClientInfo();
  }, []);

  const handleTableChange = (pagination) => {
    setPaginationConfig({
      ...paginationConfig,
      current: pagination.current,
    });
  };

  function getItem(label, key, icon, onClick) {
    return {
      key,
      icon,
      label,
      onClick: onClick,
    };
  }

  const items = (row) => {
    return [
      getItem(
        null,
        "edit",
        <Edit
          style={{
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          }}
        />,
        () => {
          handleUpdate(row.id);
        }
      ),
      getItem(
        null,
        "print",
        <div>
          <div
            id={`printArea${row.id}`}
            ref={printRef}
            style={{ position: "absolute", left: "-10000px", top: "-10000px" }}
          />
          <PrinterOutlined
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          />
        </div>,

        () => {
          handlePrint(row);
        }
      ),
      getItem(
        null,
        "return",
        !row?.return_id ? <ReloadOutlined /> : null,
        () => {
          handleOpenConfirmationModal(row?.id);
        }
      ),
    ];
  };
  const menuProps = (row) => {
    return {
      items: items(row),
      onClick: null,
    };
  };

  return (
    <div className="m-4 h-full">
      <ModalRequisitions
        title={updateId == null ? "New requisition" : "Edit requisition"}
        handleCloseModal={handleCloseModal}
        products={products}
        desks={desks}
        id={updateId}
        open={isModalRequisitionsOpened}
      />
      <ConfirmationModal
        title={"Revert requisition"}
        text={"Are you sure you wanna revert this requisition?"}
        handleNo={handleCloseModal}
        handleYes={handleRevertRequisition}
        visible={isConfirmationModalOpened}
      />
      <h1 className="text-2xl font-semibold">Search requisitions</h1>
      <Row className="flex mt-6 mb-4 justify-between h-9" gutter={[12]}>
        <Col xl={4} lg={3} md={3} sm={24} xs={24} className="flex gap-2 h-full">
          <Input
            className="text-lg"
            placeholder={"Search a requisition"}
            onChange={(e) => {
              setFilters({ ...filters, FreeSearch: e.target.value });
            }}
          />
        </Col>
        <Col xl={6} lg={3} md={3} sm={24} xs={24} className="w-full">
          <RangePicker
            className="w-full h-full"
            onChange={(_, dates) => {
              setFilters({ ...filters, FromDate: dates[0], ToDate: dates[1] });
            }}
          />
        </Col>
        <Col xl={4} lg={3} md={3} sm={24} xs={24}>
          <Popover
            open={popoverOpen}
            placement="bottomLeft"
            title={
              <FiltersTitle
                open={popoverOpen}
                setPopoverOpen={setPopoverOpen}
              />
            }
            content={
              <FiltersContent open={popoverOpen} handleApply={handleApply} />
            }
            trigger="click"
            arrow={false}
          >
            <Button
              className="h-full flex gap-1 bg-white"
              onClick={() => setPopoverOpen(true)}
            >
              <Filter className={"my-auto"} />
              Filters
            </Button>
          </Popover>
        </Col>

        <Col
          xl={{ span: 4, offset: 6 }}
          lg={{ span: 6, offset: 6 }}
          md={{ span: 8, offset: 4 }}
          sm={24}
          xs={24}
          className="flex h-full justify-end"
        >
          <Button
            type="primary"
            className="!bg-blue-500"
            onClick={handleNewRequisition}
          >
            <IoMdAddCircleOutline />
          </Button>
        </Col>
      </Row>

      <div className="overflow-x-auto max-w-screen flex flex-col h-5/6">
        <Table
          dataSource={data.info}
          pagination={{ ...paginationConfig }}
          onChange={handleTableChange}
          rowClassName="h-1"
          columns={[
            {
              title: "UID",
              dataIndex: "uid",
              key: "RequisitionUID",
              render: (RequisitionUID) => <>{RequisitionUID.split("-")[0]}</>,
            },
            {
              title: "Product",
              dataIndex: "productName",
              key: "productName",
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              key: "Quantity",
            },
            {
              title: "Client",
              dataIndex: "client_name",
              key: "client_name",
            },
            {
              title: "E-mail",
              dataIndex: "client_email",
              key: "client_email",
              width: 110,
              render: (client_email) => (
                <>{EntruncateString(15, client_email)}</>
              ),
            },
            {
              title: "Room",
              dataIndex: "room",
              key: "room",
            },
            {
              title: "User",
              dataIndex: "employeeId",
              key: "EmployeeId",
            },
            {
              title: "Desk",
              dataIndex: "deskDescription",
              key: "deskId",
            },
            {
              title: "Deposit (€)",
              dataIndex: "total",
              key: "Total",
              align: "right",
              render: (Total) => <>{Total.toFixed(2)}</>,
            },
            {
              title: "Requisition Date",
              dataIndex: "createdAt",
              key: "AcquiredTime",
              render: (AquiredTime) => (
                <>{moment(AquiredTime).format("YYYY-MM-DD")}</>
              ),
            },
            {
              title: "Check-In",
              dataIndex: "resCheckIn",
              key: "checkIn",
              render: (ci) => {
                return (
                <>
                  {moment(ci).isValid() ? (
                    moment(ci).format("YYYY-MM-DD")
                  ) : (
                    <></>
                  )}
                </>
              )},
            },
            {
              title: "Check-Out",
              dataIndex: "resCheckOut",
              key: "checkOut",
              render: (co) => (
                <>
                  {moment(co).isValid() ? (
                    moment(co).format("YYYY-MM-DD")
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              render: (_, row) =>
                row.return_id ? (
                  <div className="flex gap-2 bg-green-300 py-1 px-3 rounded-full justify-center text-center">
                    <div className="w-2 h-2 bg-green-500 rounded-full my-auto" />
                    <div>Returned</div>
                  </div>
                ) : (
                  <div className="flex gap-2 bg-sky-300 py-1 px-3 rounded-full justify-center text-center">
                    <div className="w-2 h-2 bg-sky-500 rounded-full my-auto" />
                    <div>On going</div>
                  </div>
                ),
            },
            {
              title: "",
              key: "id",
              fixed: "right",
              render: (_, row) => (
                <Dropdown menu={menuProps(row)} placement="bottom">
                  <Button>
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              ),
            },
          ]}
        ></Table>
      </div>
    </div>
  );
};

export default TableRequisitions;
