import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Requisitions from './Pages/Requisitions';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './icons/style.css';
import { AppProvider } from './AppContext';
import './index.css'

function App() {
    return (
      <AppProvider>
        <Router>
          <div className="flex">
            {/* <Sidebar /> */}
            <div className="flex-1 overflow-y-auto">
              <Routes>
                {/* <Route path="/clients" element={<Clients />} />
              <Route path="/employees" element={<Employees />} /> */}
                <Route path="/" element={<Requisitions />} />
                {/* <Route path="/" element={<General />} /> */}
              </Routes>
            </div>
          </div>
          <ToastContainer />
        </Router>
      </AppProvider>
    );
}


export default App;
